<template>
  <div class="tw-mb-4 tw-flex-grow tw-overflow-y-auto tw-overscroll-contain">
    <transition
      enter-active-class="fade-enter-transition"
      enter-from-class="fade-out"
      leave-active-class="fade-leave-transition"
      leave-to-class="fade-out"
    >
      <ul
        v-if="items && items.length"
      >
        <li
          v-for="item in items"
          :key="item.id"
          class="tw-mb-1 lg:tw-mb-2"
        >
          <Card
            :has-body-padding-small="true"
            :is-full-height="true"
          >
            <template #body>
              <div class="tw-flex tw-items-center">
                <Checkbox
                  class="tw-mr-4"
                  :model-value="isChecked(item)"
                  @update:model-value="(checked) => updateSelectedItems(checked, item)"
                />
                <component
                  :is="detailViewComponent"
                  v-bind="getComponentProps(item)"
                />
              </div>
            </template>
          </Card>
        </li>
      </ul>
      <NoFavoritesMessage
        v-else-if="showNoItemsMessage"
        class="tw-py-10 lg:tw-py-16"
        :view="view"
      />
    </transition>
  </div>
</template>

<script>
import FavoritesListMixin from '@@/components/Settings/Favorites/FavoritesListMixin';
import FavoritesPageMixin from '@@/components/Favorites/FavoritesPageMixin';

export default {
  name: 'SelectFavoritesList',

  mixins: [FavoritesListMixin, FavoritesPageMixin],

  props: {
    items: {
      type: Array,
      default: null,
    },
    location: {
      type: Object,
      default: null,
    },
    showNoItemsMessage: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  emits: ['input'],

  computed: {
    selectedItemIds() {
      return this.value.map(({ id }) => id);
    },
  },

  methods: {
    getComponentProps(item) {
      return {
        class: this.$style.detailView,
        isSmallImage: true,
        ...this.getDetailViewProps(item, this.location),
      };
    },

    isChecked(item) {
      return this.selectedItemIds.includes(item.id);
    },

    updateSelectedItems(checked, item) {
      const selectedItems = [...this.value];

      if (checked && this.selectedItemIds.includes(item.id) === false) {
        selectedItems.push(item);
        this.$emit('input', selectedItems);
      }
      else if (!checked && this.selectedItemIds.includes(item.id) === true) {
        const index = this.selectedItemIds.findIndex((id) => id === item.id);
        selectedItems.splice(index, 1);
        this.$emit('input', selectedItems);
      }
    },
  },
};
</script>

<style module>
.detailView {
  width: calc(100% - 2.25rem);
}
</style>
