import CamDetailView from '@@/components/Settings/Favorites/DetailViews/CamDetailView.vue';
import DailySnowDetailView from '@@/components/Settings/Favorites/DetailViews/DailySnowDetailView.vue';
import LocationDetailView from '@@/components/Settings/Favorites/DetailViews/LocationDetailView.vue';
import SeasonPassDetailView from '@@/components/Settings/Favorites/DetailViews/SeasonPassDetailView.vue';
import TrailMapDetailView from '@@/components/Settings/Favorites/DetailViews/TrailMapDetailView.vue';

export default {
  computed: {
    detailViewComponent() {
      if (this.isLocationsView) {
        return LocationDetailView;
      }

      if (this.isDailySnowsView) {
        return DailySnowDetailView;
      }

      if (this.isCamsView) {
        return CamDetailView;
      }

      if (this.isSeasonPassesView) {
        return SeasonPassDetailView;
      }

      if (this.isTrailMapsView) {
        return TrailMapDetailView;
      }

      return null;
    },
  },

  methods: {
    getDetailViewProps(item, location) {
      if (this.isLocationsView) {
        return { location: item };
      }

      if (this.isDailySnowsView) {
        return { dailyRead: item };
      }

      if (this.isCamsView) {
        return { cam: item, location };
      }

      if (this.isSeasonPassesView) {
        return { seasonPass: item };
      }

      if (this.isTrailMapsView) {
        return { location, trailMap: item };
      }

      return null;
    },
  },
};
