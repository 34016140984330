<template>
  <DetailView
    :image-url="authorPhotoUrl"
    :is-small-image="isSmallImage"
    :subtitle="subtitle"
    :subtitle2="subtitle2"
    :title="dailySnowName"
  />
</template>

<script>
export default {
  name: 'DailySnowDetailView',

  props: {
    dailyRead: {
      type: Object,
      required: true,
    },
    isSmallImage: {
      type: Boolean,
      default: false,
    },
    showPostTitle: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    authorName() {
      return this.dailyRead.post.author.name;
    },

    authorPhotoUrl() {
      return this.dailyRead.post.author.photo_url;
    },

    dailySnowName() {
      return this.dailyRead.name;
    },

    postTitle() {
      return this.dailyRead.post.title;
    },

    subtitle() {
      return this.showPostTitle ? this.postTitle : this.authorName;
    },

    subtitle2() {
      return this.showPostTitle ? this.authorName : undefined;
    },
  },
};
</script>
