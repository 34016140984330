<template>
  <div class="tw-flex tw-flex-col tw-h-full">
    <header class="tw-mb-4">
      <h2 class="tw-mt-3 tw-mb-4 section-title heading-color">
        {{ headerText }}
      </h2>
      <LocationSearch
        :clear-search-on-select="true"
        @update:model-value="handleLocationSelected"
      />
    </header>
    <SelectFavoritesList
      :items="items"
      :location="location"
      :show-no-items-message="showNoItemsMessage"
      :value="selectedItems"
      :view="view"
      @input="updateSelectedItems"
    />
    <footer>
      <Button
        v-bind="buttonProps"
        @click="addFavoriteItems"
      >
        {{ buttonText }}
      </Button>
    </footer>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { ButtonTypes } from '@@/components/Common/Button.vue';
import FavoritesPageMixin from '@@/components/Favorites/FavoritesPageMixin';
import { useLocationStore } from '@@/stores/Location';
import { useUserFavoritesStore } from '@@/stores/UserFavorites';

export default {
  name: 'SelectFavoriteItemsByLocation',

  mixins: [FavoritesPageMixin],

  emits: ['close', 'hide-loading', 'show-loading'],

  data() {
    return {
      items: null,
      location: null,
      selectedItems: [],
    };
  },

  computed: {
    ...mapState(useUserFavoritesStore, {
      favoriteTrailMaps: (state) => state.user?.favorites?.trailMaps,
      favoriteWebcams: (state) => state.user?.favorites?.webcams,
    }),

    buttonProps() {
      return {
        class: 'tw-w-full',
        disabled: !this.selectedItems.length,
        dislpayBlock: true,
        type: ButtonTypes.primary,
      };
    },

    buttonText() {
      if (this.isCamsView) {
        return 'Add Cams';
      }

      return 'Add Trail Maps';
    },

    headerText() {
      if (!this.location) {
        return 'Select a Location';
      }

      return this.isCamsView ? 'Select Cams' : 'Select Trail Maps';
    },

    showNoItemsMessage() {
      return this.location && this.items?.length === 0;
    },
  },

  methods: {
    ...mapActions(useLocationStore, ['fetchCams', 'fetchTrailMaps']),
    ...mapActions(useUserFavoritesStore, ['addFavoriteCam', 'addFavoriteTrailMap']),

    async addFavoriteItems() {
      /* eslint no-await-in-loop: off */

      if (this.selectedItems.length === 0) {
        return;
      }

      let message = 'Your favorites were updated successfully.';
      let type = 'success';

      this.$emit('show-loading');
      await this.$nextTick();

      try {
        for (let i = 0; i < this.selectedItems.length; i += 1) {
          const { id } = this.selectedItems[i];

          if (this.isCamsView) {
            await this.addFavoriteCam({ id });
          }
          else {
            await this.addFavoriteTrailMap({ id });
          }
        }
      }
      catch (e) {
        message = 'Unable to update your favorites! Please try again.';
        type = 'error';
      }

      this.$emit('hide-loading');
      await this.$nextTick();

      this.$toast.open({ message, type });
      this.$emit('close', { countFavoritesAdded: this.selectedItems.length });
    },

    async handleLocationSelected({ slug }) {
      /* eslint camelcase: off */

      // Reset the selected items when the user selects a location
      this.selectedItems = [];

      // Then fetch the cams/trail maps to display in the favorite selection list
      if (this.isCamsView) {
        this.location = await this.fetchCams({ slug });
        this.items = this.location.cams;
      }
      else {
        this.location = await this.fetchTrailMaps({ slug });
        this.items = this.location.trail_maps;
      }
    },

    updateSelectedItems(selectedItems) {
      this.selectedItems = selectedItems;
    },
  },
};
</script>
