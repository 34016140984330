<script>
/* eslint vue/no-v-html: off */
export const emptyIconViews = {
  countryLocations: 'countryLocations',
  editFavoriteCams: 'editFavoriteCams',
  editFavoriteDailyReads: 'editFavoriteDailyReads',
  editFavoriteLocations: 'editFavoriteLocations',
  editFavoriteSeasonPasses: 'editFavoriteSeasonPasses',
  editFavoriteTrailMaps: 'editFavoriteTrailMaps',
  favoriteCams: 'favoriteCams',
  favoriteDailyReads: 'favoriteDailyReads',
  favoriteLocations: 'favoriteLocations',
  favoriteSeasonPasses: 'favoriteSeasonPasses',
  favoriteTrailMaps: 'favoriteTrailMaps',
  locationCams: 'locationCams',
  locationDailyReads: 'locationDailyReads',
  locationTrailMaps: 'locationTrailMaps',
  locationWeatherStations: 'locationWeatherStations',
  myLocation: 'myLocation',
  notificationsAlertHistory: 'notificationsAlertHistory',
  notificationsAlerts: 'notificationsAlerts',
  notificationsDailyReads: 'notificationsDailyReads',
  powderFinder: 'powderFinder',
  regionLocations: 'regionLocations',
  seasonPassLocations: 'seasonPassLocations',
  stateLocations: 'stateLocations',
};
</script>

<script setup>
const props = defineProps({
  customMessage: {
    type: String,
    default: null,
  },
  view: {
    type: String,
    required: true,
    validate(value) {
      return Object.keys(emptyIconViews).includes(value);
    },
  },
});

const icon = computed(() => {
  if (props.view === emptyIconViews.countryLocations) return ['fad', 'earth-americas'];
  if (props.view === emptyIconViews.editFavoriteCams) return ['fad', 'camera-movie'];
  if (props.view === emptyIconViews.editFavoriteDailyReads) return ['fad', 'users'];
  if (props.view === emptyIconViews.editFavoriteLocations) return ['fad', 'mountain'];
  if (props.view === emptyIconViews.editFavoriteSeasonPasses) return ['fad', 'id-badge'];
  if (props.view === emptyIconViews.editFavoriteTrailMaps) return ['fad', 'map-location-dot'];
  if (props.view === emptyIconViews.favoriteCams) return ['fad', 'camera-movie'];
  if (props.view === emptyIconViews.favoriteDailyReads) return ['fad', 'users'];
  if (props.view === emptyIconViews.favoriteLocations) return ['fad', 'mountain'];
  if (props.view === emptyIconViews.favoriteSeasonPasses) return ['fad', 'id-badge'];
  if (props.view === emptyIconViews.favoriteTrailMaps) return ['fad', 'map-location-dot'];
  if (props.view === emptyIconViews.locationCams) return ['fad', 'camera-movie'];
  if (props.view === emptyIconViews.locationDailyReads) return ['fad', 'users'];
  if (props.view === emptyIconViews.locationTrailMaps) return ['fad', 'map-location-dot'];
  if (props.view === emptyIconViews.locationWeatherStations) return ['fad', 'tower-cell'];
  if (props.view === emptyIconViews.myLocation) return ['fad', 'map-location-dot'];
  if (props.view === emptyIconViews.notificationsAlerts) return ['fad', 'bell-on'];
  if (props.view === emptyIconViews.notificationsAlertHistory) return ['fad', 'bell-on'];
  if (props.view === emptyIconViews.notificationsDailyReads) return ['fad', 'users'];
  if (props.view === emptyIconViews.powderFinder) return ['fad', 'earth-americas'];
  if (props.view === emptyIconViews.regionLocations) return ['fad', 'earth-americas'];
  if (props.view === emptyIconViews.seasonPassLocations) return ['fad', 'earth-americas'];
  if (props.view === emptyIconViews.stateLocations) return ['fad', 'earth-americas'];
  return null;
});

const message = computed(() => {
  if (props.customMessage) return props.customMessage;
  if (props.view === emptyIconViews.countryLocations) return 'Sorry, we do not have locations for this country.';
  if (props.view === emptyIconViews.editFavoriteCams) return 'You have no favorite cams!';
  if (props.view === emptyIconViews.editFavoriteDailyReads) return 'You have no favorite daily snow forecasts!';
  if (props.view === emptyIconViews.editFavoriteLocations) return 'You have no favorite locations!';
  if (props.view === emptyIconViews.editFavoriteSeasonPasses) return 'You have no favorite season passes!';
  if (props.view === emptyIconViews.editFavoriteTrailMaps) return 'You have no favorite trail maps!';
  if (props.view === emptyIconViews.favoriteCams) return 'Add your first favorite cam.<br> This helps you to compare conditions at multiple areas on one screen.';
  if (props.view === emptyIconViews.favoriteDailyReads) return 'Add your first favorite daily snow forecast.<br> A daily snow is a forecast written by a local expert.';
  if (props.view === emptyIconViews.favoriteLocations) return 'Add your first favorite location, like a ski area or town.<br> This helps you to compare multiple areas on one screen.';
  if (props.view === emptyIconViews.favoriteSeasonPasses) return 'Add your first favorite season pass.<br> This helps you to track conditions for all mountains included with your ski pass.';
  if (props.view === emptyIconViews.favoriteTrailMaps) return 'Add your first favorite Trail Map.<br> This gives you quick access to the digital version of the paper maps you use most often.';
  if (props.view === emptyIconViews.locationCams) return 'Sorry, we do not have cams for this location.';
  if (props.view === emptyIconViews.locationDailyReads) return 'Sorry, we do not have daily snow forecasts available for this location.<br> A daily snow is a forecast written by a local expert.';
  if (props.view === emptyIconViews.locationTrailMaps) return 'Sorry, we do not have trail maps for this location.';
  if (props.view === emptyIconViews.locationWeatherStations) return 'Sorry, there are no nearby weather stations.';
  if (props.view === emptyIconViews.notificationsAlerts) return 'You have no snow notifications!';
  if (props.view === emptyIconViews.notificationsAlertHistory) return 'You have no alert history!';
  if (props.view === emptyIconViews.notificationsDailyReads) return 'You have no expert local forecaster notifications.';
  if (props.view === emptyIconViews.powderFinder) return 'No locations found.';
  if (props.view === emptyIconViews.regionLocations) return 'Sorry, we do not have locations for this region.';
  if (props.view === emptyIconViews.seasonPassLocations) return 'Sorry, we do not have locations for this season pass.';
  if (props.view === emptyIconViews.stateLocations) return 'Sorry, we do not have locations for this state.';
  return '';
});
</script>

<template>
  <div>
    <font-awesome-icon
      v-if="icon"
      class="tw-block tw-h-12 tw-w-12 tw-mx-auto tw-mb-3 text-opensnow-blue-color"
      :icon="icon"
    />
    <p
      v-if="message"
      :class="[
        'tw-text-normal tw-text-center text-regular-color',
        $style.message,
      ]"
      v-html="message"
    />
  </div>
</template>

<style module>
.message br {
  display: none;
}

@media (min-width: 992px) {
  .message br {
    display: block;
  }
}
</style>
