<template>
  <div :class="additionalClassNames">
    <div :class="emptyIconContainerClassName">
      <EmptyIcon
        v-if="emptyIconView"
        :custom-message="customMessage"
        :view="emptyIconView"
      />
    </div>
    <CamsUrlButton
      v-if="showCamsUrlButton"
      :has-cams="hasCams"
      :location="location"
    />
    <Button
      v-if="isFavorites"
      v-bind="buttonProps"
      @click="handleButtonClick"
    >
      {{ buttonText }}
    </Button>
    <AddFavoritesDialog
      v-if="isFavorites"
      :show-dialog="showDialog"
      :view="view"
      @close="handleDialogClose"
    />
  </div>
</template>

<script>
import { ButtonTypes } from '@@/components/Common/Button.vue';
import { emptyIconViews } from '@@/components/Common/EmptyIcon.vue';
import FavoritesPageMixin from '@@/components/Favorites/FavoritesPageMixin';

/**
 * @todo Rename this component to something different? <NoResourcesMessage> maybe?
 */
export default {
  name: 'NoFavoritesMessage',

  mixins: [FavoritesPageMixin],

  props: {
    additionalClassNames: {
      type: String,
      default: null,
    },
    compareType: {
      type: String,
      default: null,
    },
    hasCams: {
      type: Boolean,
      default: false,
    },
    hasCamsUrl: {
      type: Boolean,
      default: false,
    },
    isEditFavorites: {
      type: Boolean,
      default: false,
    },
    isFavorites: {
      type: Boolean,
      default: false,
    },
    location: {
      type: Object,
      default: null,
    },
  },

  emits: ['favorites-added', 'show-add-favorites-dialog'],

  data() {
    return {
      showDialog: false,
    };
  },

  computed: {
    buttonProps() {
      return {
        additionalClassNames: 'tw-w-full md:tw-w-1/2 lg:tw-w-1/4 tw-mx-auto',
        displayBlock: true,
        type: ButtonTypes.primary,
      };
    },

    buttonText() {
      if (this.isCamsView) {
        return 'Add Cam';
      }

      if (this.isDailySnowsView) {
        return 'Add Daily Snow Forecast';
      }

      if (this.isLocationsView) {
        return 'Add Location';
      }

      if (this.isSeasonPassesView) {
        return 'Add Season Pass';
      }

      if (this.isTrailMapsView) {
        return 'Add Trail Maps';
      }

      return '';
    },

    customMessage() {
      if (this.isCamsView & this.hasCamsUrl && this.location?.name) {
        return `View ${this.location.name} cams on their website.`;
      }

      return '';
    },

    /**
     * Display bottom margin below icon if a button will be displayed after it.
     */
    emptyIconContainerClassName() {
      return this.showCamsUrlButton || this.isFavorites ? 'tw-mb-4' : '';
    },

    emptyIconView() {
      if (this.compareType) {
        if (this.compareType === 'states') return emptyIconViews.stateLocations;
        if (this.compareType === 'countries') return emptyIconViews.countryLocations;
        if (this.compareType === 'regions') return emptyIconViews.regionLocations;
        if (this.compareType === 'season-passes') return emptyIconViews.seasonPassLocations;
        return null;
      }

      if (this.isEditFavorites) {
        if (this.isCamsView) return emptyIconViews.editFavoriteCams;
        if (this.isDailySnowsView) return emptyIconViews.editFavoriteDailyReads;
        if (this.isLocationsView) return emptyIconViews.editFavoriteLocations;
        if (this.isSeasonPassesView) return emptyIconViews.editFavoriteSeasonPasses;
        if (this.isTrailMapsView) return emptyIconViews.editFavoriteTrailMaps;
        return null;
      }

      if (this.isFavorites) {
        if (this.isCamsView) return emptyIconViews.favoriteCams;
        if (this.isDailySnowsView) return emptyIconViews.favoriteDailyReads;
        if (this.isLocationsView) return emptyIconViews.favoriteLocations;
        if (this.isSeasonPassesView) return emptyIconViews.favoriteSeasonPasses;
        if (this.isTrailMapsView) return emptyIconViews.favoriteTrailMaps;
        return null;
      }

      if (this.isCamsView) return emptyIconViews.locationCams;
      if (this.isDailySnowsView) return emptyIconViews.locationDailyReads;
      if (this.isTrailMapsView) return emptyIconViews.locationTrailMaps;
      if (this.isWeatherStationsView) return emptyIconViews.locationWeatherStations;

      return null;
    },

    showCamsUrlButton() {
      return this.isCamsView && !this.isFavorites && this.hasCamsUrl;
    },
  },

  methods: {
    handleButtonClick() {
      this.showDialog = true;
      this.$emit('show-add-favorites-dialog', this.showDialog);
    },

    handleDialogClose(payload) {
      this.showDialog = false;

      if (payload?.countFavoritesAdded > 0) {
        this.$emit('favorites-added', payload.countFavoritesAdded);
      }

      this.$emit('show-add-favorites-dialog', this.showDialog);
    },
  },
};
</script>
