<template>
  <DetailView
    :image-url="imageUrl"
    :is-small-image="isSmallImage"
    :subtitle="camName"
    :title="locationName"
  />
</template>

<script>
export default {
  name: 'CamDetailView',

  props: {
    cam: {
      type: Object,
      required: true,
    },
    isSmallImage: {
      type: Boolean,
      default: false,
    },
    location: {
      type: Object,
      required: true,
    },
  },

  computed: {
    camName() {
      return this.cam.name;
    },

    imageUrl() {
      return this.cam.image_url;
    },

    locationName() {
      return this.location.name;
    },
  },
};
</script>
