<template>
  <div class="tw-text-center">
    <Button
      :href="location.cams_url"
      :display-block="displayBlock"
      rel="noopener noreferrer"
      target="_blank"
      :type="buttonType"
    >
      {{ buttonText }}
      <font-awesome-icon
        v-if="showIcon"
        class="tw-ml-1"
        :icon="['fas', 'arrow-alt-circle-right']"
      />
    </Button>
  </div>
</template>

<script>
import { ButtonTypes } from '@@/components/Common/Button.vue';

export default {
  name: 'CamsUrlButton',

  props: {
    hasCams: {
      type: Boolean,
      required: true,
    },
    location: {
      type: Object,
      required: true,
    },
  },

  computed: {
    buttonType() {
      return this.hasCams ? ButtonTypes.card : ButtonTypes.primary;
    },

    buttonText() {
      return this.hasCams ? 'View the cams on the resort website' : 'View Live Cams';
    },

    displayBlock() {
      return this.hasCams;
    },

    showIcon() {
      return this.hasCams;
    },
  },
};
</script>
