<template>
  <div class="tw-flex tw-flex-col tw-h-full">
    <header class="tw-mb-4">
      <h2 class="tw-mt-3 tw-mb-4 section-title heading-color">
        Add Locations
      </h2>
      <LocationSearch
        :clear-search-on-select="true"
        @update:model-value="addSelectedLocation"
      />
    </header>
    <SelectFavoritesList
      :items="selectedLocations"
      :value="selectedLocations"
      :view="view"
      @input="updateSelectedLocations"
    />
    <footer>
      <Button
        v-bind="buttonProps"
        @click="addFavoriteLocations"
      >
        Add Locations
      </Button>
      <AddRelatedFavoritesDisclaimer
        additional-class-names="tw-px-2 tw-py-2"
        @close-dialog="$emit('close')"
      />
    </footer>
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import { ButtonTypes } from '@@/components/Common/Button.vue';
import FavoritesPageMixin from '@@/components/Favorites/FavoritesPageMixin';
import { useUserFavoritesStore } from '@@/stores/UserFavorites';

export default {
  name: 'SelectFavoriteLocations',

  mixins: [FavoritesPageMixin],

  emits: ['close', 'hide-loading', 'show-loading'],

  data() {
    return {
      locations: null,
      selectedLocations: [],
    };
  },

  computed: {
    buttonProps() {
      return {
        class: 'tw-w-full',
        disabled: !this.selectedLocations.length,
        dislpayBlock: true,
        type: ButtonTypes.primary,
      };
    },
  },

  methods: {
    ...mapActions(useUserFavoritesStore, ['addFavoriteLocation']),

    addSelectedLocation(location) {
      const selectedLocationIds = this.selectedLocations.map(({ id }) => id);

      if (selectedLocationIds.includes(location.id) === false) {
        this.selectedLocations.splice(0, 0, location);
      }
    },

    async addFavoriteLocations() {
      /* eslint no-await-in-loop: off */

      if (this.selectedLocations.length === 0) {
        return;
      }

      let message = 'Your favorites were updated successfully.';
      let type = 'success';

      this.$emit('show-loading');
      await this.$nextTick();

      try {
        for (let i = 0; i < this.selectedLocations.length; i += 1) {
          const { id } = this.selectedLocations[i];
          await this.addFavoriteLocation({ id });
        }
      }
      catch (e) {
        message = 'Unable to add locations to your favorites! Please try again.';
        type = 'error';
      }

      this.$emit('hide-loading');
      await this.$nextTick();

      this.$toast.open({ message, type });
      this.$emit('close', { countFavoritesAdded: this.selectedLocations.length });
    },

    updateSelectedLocations(selectedLocations) {
      this.selectedLocations = selectedLocations;
    },
  },
};
</script>
