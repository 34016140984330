<template>
  <DetailView
    :image-url="imageUrl"
    :is-custom-location="isCustomLocation"
    :is-small-image="isSmallImage"
    :subtitle="subtitle"
    :title="locationName"
  />
</template>

<script>
export default {
  name: 'LocationDetailView',

  props: {
    isSmallImage: {
      type: Boolean,
      default: false,
    },
    location: {
      type: Object,
      required: true,
    },
  },

  computed: {
    countryName() {
      return this.location.country_name;
    },

    imageUrl() {
      return this.location.image_url || this.location.icon_url;
    },

    isCustomLocation() {
      return this.location.is_custom;
    },

    locationName() {
      return this.location.name || this.location.label;
    },

    stateName() {
      return this.location.state_name ?? '';
    },

    subtitle() {
      if (this.stateName && this.countryName) {
        return `${this.stateName} • ${this.countryName}`;
      }

      if (this.countryName) {
        return this.countryName;
      }

      if (this.location.detail) {
        return this.location.detail;
      }

      return '';
    },
  },
};
</script>
