<template>
  <div :class="$style.container">
    <div :class="textContainerClass">
      <ResourceHeader
        :image-url="imageUrl"
        :size="size"
      >
        <template #title>
          <span :class="titleClass">
            {{ title }}
          </span>
        </template>
        <template
          v-if="subtitle"
          #subtitle
        >
          <span :class="subtitleClass">
            {{ subtitle }}
          </span>
        </template>
        <template
          v-if="subtitle2 || hasDetailsSlot"
          #details
        >
          <slot name="details" />
          {{ subtitle2 }}
        </template>
      </ResourceHeader>
    </div>
    <img
      v-if="isCustomLocation"
      :class="[
        'tw-block tw-h-4 tw-w-4 lg:tw-h-5 lg:tw-w-5 md:tw-ml-2.5',
        'dark-invert-100%',
        $style.customLocationIcon,
      ]"
      src="~/assets/img/ForecastAnywhere.png"
    >
  </div>
</template>

<script>
export default {
  name: 'DetailView',

  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    isCustomLocation: {
      type: Boolean,
      default: false,
    },
    isSmallImage: {
      type: Boolean,
      default: false,
    },
    subtitle: {
      type: String,
      default: null,
    },
    subtitle2: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
  },

  computed: {
    hasDetailsSlot() {
      return !!this.$slots.details;
    },

    overflowClass() {
      return 'tw-overflow-hidden tw-whitespace-nowrap tw-overflow-ellipsis';
    },

    titleClass() {
      return [
        'tw-block',
        this.overflowClass,
      ];
    },

    size() {
      return this.isSmallImage ? 'small' : 'medium';
    },

    subtitleClass() {
      return [
        'text-regular-color',
        this.overflowClass,
        this.$style.subtitle,
      ];
    },

    textContainerClass() {
      return this.isCustomLocation
        ? this.$style.textContainerCustomLocation
        : 'tw-w-full';
    },
  },
};
</script>

<style module>
/* stylelint-disable selector-class-pattern */

.container {
  display: flex;
}

.subtitle {
  display: block;
}

:global(.vs__dropdown-option--highlight) .container .subtitle {
  color: white;
}

:global(.vs__dropdown-option--highlight) .container .customLocationIcon {
  filter: invert(100%);
}

/*
 * On mobile set width of text container to 100% minus width of the custom location icon so
 * overflowing text can be cropped while still displaying the forecast anywhere icon. On tablet
 * and desktop, display the forecast anywhere icon just to the right of the location name.
 *
 * While the tablet and desktop display is not consistent with the /user/favorites/locations page,
 * it looks better. Displaying the forecast anywhere icon floating far to the right on desktop looks
 * awkward.
 */
.textContainerCustomLocation {
  width: calc(100% - 1rem);
}

@media (min-width: 768px) {
  .textContainerCustomLocation {
    width: auto;
  }
}
</style>
