<template>
  <div class="tw-flex tw-flex-col tw-h-full">
    <header class="tw-mb-4">
      <h2 class="tw-mt-3 tw-mb-4 section-title heading-color">
        {{ label }}
      </h2>
    </header>
    <SelectFavoritesList
      :items="items"
      :value="selectedItems"
      :view="view"
      @input="updateSelectedItems"
    />
    <footer>
      <Button
        v-bind="buttonProps"
        @click="updateFavoriteItems"
      >
        {{ label }}
      </Button>
    </footer>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import cloneDeep from 'lodash.clonedeep';
import { ButtonTypes } from '@@/components/Common/Button.vue';
import FavoritesPageMixin from '@@/components/Favorites/FavoritesPageMixin';
import { useDailyReadsStore } from '@@/stores/DailyReads';
import { useMetaStore } from '@@/stores/Meta';
import { useUserFavoritesStore } from '@@/stores/UserFavorites';

export default {
  name: 'SelectFavoriteItems',

  mixins: [FavoritesPageMixin],

  emits: ['close', 'hide-loading', 'show-loading'],

  data() {
    return {
      items: null,
      selectedItems: [],
    };
  },

  computed: {
    ...mapState(useUserFavoritesStore, {
      favoriteDailyReads: (state) => state.user?.favorites?.dailyReads,
      favoriteSeasonPasses: (state) => state.user?.favorites?.seasonPasses,
    }),

    buttonProps() {
      return {
        class: 'tw-w-full',
        disabled: !this.selectedItems.length,
        dislpayBlock: true,
        type: ButtonTypes.primary,
      };
    },

    label() {
      if (this.isDailySnowsView) {
        return 'Add Daily Snows';
      }

      return 'Add Season Passes';
    },
  },

  mounted() {
    this.handleMounted();
  },

  methods: {
    ...mapActions(useDailyReadsStore, ['fetchDailyReads']),
    ...mapActions(useMetaStore, ['fetchSeasonPasses']),
    ...mapActions(useUserFavoritesStore, ['updateFavoriteDailyReads', 'updateFavoriteSeasonPasses']),

    async handleMounted() {
      if (this.isDailySnowsView) {
        this.items = await this.fetchDailyReads({ sort: 'A-Z' });
        this.selectedItems = this.favoriteDailyReads ? cloneDeep(this.favoriteDailyReads) : [];
      }
      else {
        this.items = await this.fetchSeasonPasses();
        this.selectedItems = this.favoriteSeasonPasses ? cloneDeep(this.favoriteSeasonPasses) : [];
      }
    },

    async updateFavoriteItems() {
      if (this.selectedItems.length === 0) {
        return;
      }

      let message = 'Your favorites were updated successfully.';
      let type = 'success';

      this.$emit('show-loading');
      await this.$nextTick();

      try {
        const payload = { favorites: this.selectedItems };

        if (this.isDailySnowsView) {
          await this.updateFavoriteDailyReads(payload);
        }
        else {
          await this.updateFavoriteSeasonPasses(payload);
        }
      }
      catch (e) {
        message = 'Unable to update your favorites! Please try again.';
        type = 'error';
      }

      this.$emit('hide-loading');
      await this.$nextTick();

      this.$toast.open({ message, type });
      this.$emit('close', { countFavoritesAdded: this.selectedItems.length });
    },

    updateSelectedItems(selectedItems) {
      this.selectedItems = selectedItems;
    },
  },
};
</script>
