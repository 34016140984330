<template>
  <Dialog
    :show-dialog="showDialog"
    @close="handleClose"
  >
    <Card
      :card-class-names="$style.container"
      :has-body-padding-small="true"
      :has-body-shadow="true"
      :show-close-button="true"
      @close-button-click="handleClose"
    >
      <template #body>
        <transition
          enter-active-class="fade-enter-transition"
          enter-from-class="fade-out"
          leave-active-class="fade-leave-transition"
          leave-to-class="fade-out"
        >
          <div
            v-if="showLoading"
            :class="loadingClass"
          >
            <LoadingIndicator />
          </div>
        </transition>
        <component
          :is="component"
          :view="view"
          @close="handleClose"
          @hide-loading="updateShowLoading(false)"
          @show-loading="updateShowLoading(true)"
        />
      </template>
    </Card>
  </Dialog>
</template>

<script>
import FavoritesPageMixin from '@@/components/Favorites/FavoritesPageMixin';
import SelectFavoriteItems from '@@/components/Settings/Favorites/AddFavoritesToList/SelectFavoriteItems.vue';
import SelectFavoriteItemsByLocation from '@@/components/Settings/Favorites/AddFavoritesToList/SelectFavoriteItemsByLocation.vue';
import SelectFavoriteLocations from '@@/components/Settings/Favorites/AddFavoritesToList/SelectFavoriteLocations.vue';

export default {
  name: 'AddFavoritesDialog',

  mixins: [FavoritesPageMixin],

  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['close'],

  data() {
    return {
      showLoading: false,
    };
  },

  computed: {
    component() {
      if (this.isLocationsView) {
        return SelectFavoriteLocations;
      }

      if (this.isDailySnowsView || this.isSeasonPassesView) {
        return SelectFavoriteItems;
      }

      if (this.isCamsView || this.isTrailMapsView) {
        return SelectFavoriteItemsByLocation;
      }

      return null;
    },

    loadingClass() {
      return [
        'tw-absolute tw-w-full tw-h-full tw-left-0 tw-top-0 tw-z-20',
        this.$style.loading,
      ];
    },
  },

  methods: {
    handleClose(payload) {
      this.$emit('close', payload);
    },

    updateShowLoading(showLoading) {
      this.showLoading = showLoading;
    },
  },
};
</script>

<style module>
.container {
  height: 85vh;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: 90vw;
}

@media (min-width: 992px) {
  .container {
    height: 40rem;
    width: 30rem;
  }
}

.loading {
  background-color: rgb(var(--card-background-rgb) / 50%);
}
</style>
