<template>
  <div :class="containerClass">
    <v-select
      v-bind="$props"
      :clearable="false"
      :searchable="searchable"
      @close="$emit('close')"
      @update:model-value="handleUpdateModelValue"
      @search="(search, toggleLoading) => $emit('search', search, toggleLoading)"
    >
      <!--
        Pass scoped slots from parent to <v-select> child component.
        SEE: https://stackoverflow.com/questions/50891858/vue-how-to-pass-down-slots-inside-wrapper-component/52823029#52823029
      -->
      <template
        v-for="(_, scopedSlotName) in $slots"
        #[scopedSlotName]="slotData"
      >
        <slot
          :name="scopedSlotName"
          v-bind="slotData"
        />
      </template>
    </v-select>
  </div>
</template>

<script>
/* eslint vue/no-reserved-component-names: off */

/**
 * Custom wrapper for vue-select to provide OpenSnow specific styling.
 * @see https://vue-select.org/
 */
import vSelect from 'vue-select';

export default {
  name: 'Select',

  components: {
    vSelect,
  },

  props: {
    appendToBody: {
      type: Boolean,
      default: false,
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: 'label',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    noDrop: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    reduce: {
      type: Function,
      default: (option) => option,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Function,
      default: () => true,
    },
    uid: {
      type: String,
      default: undefined,
    },
    modelValue: {
      type: [Number, Object, String],
      default: null,
    },
  },

  emits: ['close', 'search', 'update:modelValue'],

  computed: {
    containerClass() {
      return [
        'tw-inline-block',
        'tw-font-normal',
        this.$style.container,
        !this.placeholder || this.searchable === false ? this.$style.hideSearch : null,
      ];
    },
  },

  methods: {
    handleUpdateModelValue(value) {
      this.$emit('update:modelValue', value);
    },
  },
};
</script>

<style module>
/* stylelint-disable selector-class-pattern, custom-property-pattern */
.container {
  --vs-border-color: var(--border-color);
  --vs-border-radius: 0.625rem;
  --vs-controls-color: var(--text-regular);
  --vs-dropdown-bg: var(--card-background);
  --vs-dropdown-color: var(--text-darkest);
  --vs-search-input-placeholder-color: var(--text-light);
  --vs-selected-color: var(--text-darkest);
  --vs-state-disabled-bg: var(--card-background);
  --vs-state-disabled-color: var(--text-light);
}

:global(.vs__dropdown-toggle) {
  background-color: var(--card-background);
  padding-bottom: 0.375rem;
  padding-top: 0.125rem;
}

:global(.vs__dropdown-menu) {
  --vs-border-color: var(--border-color);
  --vs-dropdown-bg: var(--card-background);
  --vs-dropdown-color: var(--text-darkest);
  --vs-dropdown-option--active-bg: var(--light-blue);
  --vs-dropdown-option--active-color: white;
  --vs-selected-color: var(--text-darkest);
  --vs-state-disabled-bg: var(--card-background);
  --vs-state-disabled-color: var(--text-light);
}

.hideSearch :global(.vs__search) {
  flex-grow: 0;
  padding: 0;
}

.container :global(.vs--multiple .vs__selected) {
  background-color: var(--light-blue);
  border-color: var(--light-blue);
  border-radius: 0.25rem;
  color: white;
}

.container :global(.vs--multiple .vs__deselect) {
  fill: white;
}
/* stylelint-enable selector-class-pattern */
</style>
