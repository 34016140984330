<template>
  <div>
    <Select
      class="tw-w-full"
      label="name"
      :no-drop="noDrop"
      :options="locations"
      placeholder="Search locations"
      :reduce="(location) => location.id"
      :searchable="true"
      :model-value="selectedLocationId"
      @close="handleClose"
      @update:model-value="handleUpdateModelValue"
      @search="handleSearch"
    >
      <template #no-options="props">
        <span>
          No results for "{{ props.search }}".
        </span>
      </template>
      <template #option="option">
        <LocationDetailView
          class="tw-my-1"
          :is-small-image="true"
          :location="option"
        />
      </template>
    </Select>
  </div>
</template>

<script>
/**
 * @todo
 * For Cam and Trail Map selection:
 *
 * - Display <LocationDetailView> with a remove icon in the selected option and remove
 *   selected location ID when remove icon clicked.
 */
import { mapActions } from 'pinia';
import debounce from 'lodash.debounce';
import { useSearchStore } from '@@/stores/Search';

export default {
  name: 'LocationSearch',

  props: {
    clearSearchOnSelect: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  data() {
    return {
      noDrop: true,
      locations: [],
      selectedLocationId: null,
    };
  },

  methods: {
    ...mapActions(useSearchStore, ['searchLocations']),

    handleClose() {
      this.locations = [];
      this.selectedLocationId = null;

      if (this.clearSearchOnSelect) {
        this.noDrop = true;
      }
    },

    handleUpdateModelValue(selectedLocationId) {
      this.selectedLocationId = selectedLocationId;
      const location = this.locations.find(({ id }) => id === this.selectedLocationId);
      this.$emit('update:modelValue', location);
    },

    handleSearch: debounce(async function handleSearch(search) {
      try {
        if (!search) {
          return;
        }

        const payload = { limit: 10, q: search };
        this.locations = await this.searchLocations(payload);
        this.noDrop = false;
      }
      catch (e) {
        // Ignore API errors and let the user try again
      }
    }, 250),
  },
};
</script>
