export default {
  props: {
    view: {
      type: String,
      required: true,
      validator(value) {
        return [
          'cams',
          'daily-snows',
          'locations',
          'season-passes',
          'trail-maps',
          'weather-stations',
        ].includes(value);
      },
    },
  },

  computed: {
    isCamsView() {
      return this.view === 'cams';
    },

    isDailySnowsView() {
      return this.view === 'daily-snows';
    },

    isLocationsView() {
      return this.view === 'locations';
    },

    isSeasonPassesView() {
      return this.view === 'season-passes';
    },

    isTrailMapsView() {
      return this.view === 'trail-maps';
    },

    isWeatherStationsView() {
      return this.view === 'weather-stations';
    },
  },
};
