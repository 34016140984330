<template>
  <DetailView
    :image-url="imageUrl"
    :is-small-image="isSmallImage"
    :subtitle="trailMapName"
    :title="locationName"
  />
</template>

<script>
export default {
  name: 'TrailMapDetailView',

  props: {
    isSmallImage: {
      type: Boolean,
      default: false,
    },
    location: {
      type: Object,
      required: true,
    },
    trailMap: {
      type: Object,
      required: true,
    },
  },

  computed: {
    imageUrl() {
      return this.trailMap.image_url;
    },

    locationName() {
      return this.location.name;
    },

    trailMapName() {
      return this.trailMap.name;
    },
  },
};
</script>
