<template>
  <DetailView
    :image-url="imageUrl"
    :is-small-image="isSmallImage"
    :subtitle="countLocations"
    :title="seasonPassName"
  />
</template>

<script>
export default {
  name: 'SeasonPassDetailView',

  props: {
    isSmallImage: {
      type: Boolean,
      default: false,
    },
    seasonPass: {
      type: Object,
      required: true,
    },
  },

  computed: {
    countLocations() {
      if (this.seasonPass.count_locations === 1) {
        return '1 location';
      }

      return `${this.seasonPass.count_locations} locations`;
    },

    imageUrl() {
      return this.seasonPass.image_url;
    },

    seasonPassName() {
      return this.seasonPass.name;
    },
  },
};
</script>
